@import "_variables";
@import "_mixins";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}

html {
    scroll-behavior: smooth;
}

html::-webkit-scrollbar {
    width: 0.6rem;
    background: rgb(219, 219, 219);
}

html::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

html::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.342);
}

::selection {
    color: $white;
    background: $primary-color;
}

.not-found-message {
    margin: 1rem 0;
    animation: shakeAnimation 0.5s linear forwards;

    p {
        background: #eeeeee;
        border: 1px solid #c5c5c5;
        border-radius: 0.4rem;
        padding: 1rem;
        font-size: 1.2rem;
        text-align: center;
    }
}

@keyframes shakeAnimation {
    0% {
        transform: translateX(0rem);
    }

    50% {
        transform: translateX(0.8rem);
    }

    80% {
        transform: translateX(-0.8rem);
    }

    100% {
        transform: translateX(0rem);
    }
}


.popup-notification-wrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.561);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup-notification-inner {
        background: $white;
        padding: 1rem;
        border: 0.4rem solid $primary-color;
        border-radius: 0.4rem;
        animation: animateNotice 0.5s ease-in forwards;

        .notification-img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80vh;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .notification-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .childs {
                &:first-child {
                    h3 {
                        color: $dark;
                        font-size: 1rem;
                    }
                }

                &:last-child {
                    button {
                        outline: none;
                        border: none;
                        background: none;
                        color: rgb(220, 0, 0);
                        cursor: pointer;

                        i {
                            font-size: 1.6rem;
                        }

                        &:host {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }
}

@keyframes animateNotice {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}


.fadeFromLeft {
    animation: 0.5s ease-in-out fadeUpAnimation forwards;
}

.fadeFromLeftAltt {
    animation: 1s ease-in-out fadeUpAnimationAlt forwards;
    animation-delay: calc(0.1s * var(--i));
}

@keyframes fadeUpAnimationAlt {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeUpAni {
    animation: 1s ease-in-out fadeUpAni forwards;
    animation-delay: calc(0.1s * var(--i));
}


@keyframes fadeUpAni {
    from {
        opacity: 0;
        transform: translateY(2rem);
    }

    to {
        opacity: 1;
        transform: translateY(0rem);
    }
}

.navigation-section .menu .menu-childs:last-child ul li .active-class-handler.active {
    color: $secondary-color;
}

.leftVal {
    left: -100% !important;
}

.studymate-header {
    margin-bottom: 1rem;

    h4 {
        font-size: 1.3rem;
        font-weight: 600;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -1rem;
            height: 100%;
            width: 0.5rem;
            border-radius: 1rem 0 1rem 0;
            background: $primary-color;
        }
    }
}

.detail-container {
    padding: 0.5rem 0 1rem 0;

    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 0.3rem;
    }
}

.detail-container-only {

    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 0.3rem;
    }
}

.studymate-header-center {
    display: flex;
    justify-content: center;
}

.studymate-header-center-only {
    margin-bottom: 1rem;

    h4 {
        font-size: 1.3rem;
        font-weight: 600;
        text-align: center;
    }

    p {
        text-align: center;
        text-transform: uppercase;
    }
}

.studymate-explain {
    font-size: 1.1rem;
    font-weight: 500;
    text-align: justify;
}

.know-more-btn {
    button {
        width: 100%;
        border: none;
        outline: none;
        margin-top: 1rem;
        background: $primary-color;
        font-size: 0.9rem;
        color: $white;
        padding: 0.3rem 0.4rem;

    }
}

.submit-btn {
    button {
        width: 100%;
        border: none;
        outline: none;
        background: $primary-color;
        font-size: 0.9rem;
        color: $white;
        padding: 0.3rem 0.4rem;
        margin-bottom: 0.4rem;

        &:hover {
            background: $secondary-color;
        }
    }
}

.studymate-breadcrumb {
    background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("../assets/images/studymate_breadcrumb.jpg");
    background-size: auto;
    padding: 4rem 0;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        margin: 0rem 1rem;
    }

    h3,
    a {
        font-size: 1.3rem;
        color: $white;
    }

    h3 {
        animation: fadeUpAnimation 0.5s linear forwards;
    }
}

// form::begin
.global-form {
    label {
        color: $dark;
        @include globalSmFont;
        font-weight: 500;
    }

    span {
        color: red;
        padding-left: 0.3rem;
    }

    input,
    select,
    textarea {
        width: 100%;
        border-radius: 0.4rem 0.4rem 0 0;
        outline: none;
        border: 0.12rem solid #cacaca;
        border-bottom: 0.16rem solid $primary-color;
        padding: 0.2rem 0.4rem;
        background: #f8f8f8;
        margin-bottom: 1rem;

        &:focus {
            border: 0.12rem solid $primary-color;
        }
    }

    .iaccept {
        input {
            width: auto;
        }

        span {
            color: $dark;
            @include globalSmFont;
        }

        p {
            color: red;
        }
    }
}

.show-hide-check {
    margin-right: 0.5rem;
}

.form-danger-info {
    font-size: 0.9rem;
    color: red;
}

.submit-btn100 {
    button {
        width: 100%;
        background: $primary-color;
        padding: 0.3rem;
        color: $white;
        outline: none;
        border: none;
        border-radius: 0.2rem;

        i {
            transform: translateX(2rem);
            transition: 0.2s;
            opacity: 0;
            margin-left: 0.4rem;
        }

        &:hover {
            background: #555555;

            i {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}

// form::end

// auth::begin
.auth-section {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $primary-color;

    .auth-wrapper {
        @include flexPropertyCentered;
        height: 100%;
        width: 100%;

        .auth-inner-wrapper {
            background: $white;
            border: $light-border;
            padding: 1rem;
            border-radius: 0.5rem;
            width: 30vw;
            animation: fadeDownAnimation 0.5s linear forwards;

            .logo-auth {
                @include flexPropertyCentered;

                img {
                    height: auto;
                    width: 12rem;
                }
            }

            h3 {
                font-size: 1.3rem;
                text-align: center;
                color: $dark;
                margin: 0.4rem 0;
            }
        }
    }
}

// auth::end

// top navigation::begin
.studymate-top-navigation {
    .top-navigation-deco {
        margin-top: -0.9rem;

        img {
            height: auto;
            width: 100%;
        }
    }

    .top-navigation-info {
        background: $primary-color;
        margin-top: 0.1rem;

        .top-navigation-parent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.3rem 0;

            .top-navigation-childs {
                &:first-child {
                    ul {
                        display: flex;

                        li {
                            list-style-type: none;
                            color: $white;
                            font-size: 0.9rem;
                            font-weight: 500;
                            margin-right: 1rem;

                            i {
                                margin-right: 0.3rem;
                            }
                        }
                    }
                }

                &:last-child {
                    ul {
                        display: flex;

                        li {
                            list-style-type: none;
                            margin-right: 0.5rem;

                            .applyBtn {
                                animation: 1s ease animateApplyBtn infinite;
                            }

                            button {
                                background: $secondary-color;
                                color: $white;
                                border: none;
                                outline: none;
                                padding: 0rem 0.4rem;
                                font-size: 0.9rem;
                                border-radius: 0.2rem;

                                &:hover {
                                    background: $white;
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes animateApplyBtn {

    0% {
        background: $primary-color;
        color: $white;
    }

    100% {
        background: $secondary-color;
        color: $white;
    }
}

// top navigation::end

// mavigation::begin
.navigation-section {
    border-bottom: 2px solid $primary-color;
    box-shadow: $box-shadow;

    .toggle-close-btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: $white;
        font-size: 1.2rem;
        cursor: pointer;
        display: none;
    }

    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .menu-childs {
            &:first-child {
                position: relative;
                width: 100%;

                img {
                    height: auto;
                    width: 12rem;
                }

                .toggle-open-btn {
                    i {
                        color: $primary-color;
                        font-size: 1.5rem;
                        position: absolute;
                        top: 30%;
                        right: 0.5rem;
                        cursor: pointer;
                    }

                    display: none;
                }
            }

            &:last-child {
                transition: $transition;

                ul {
                    display: flex;

                    .sub-menu-wrapper {
                        position: relative;
                        z-index: 100;

                        &:hover {
                            .sub-menu {
                                visibility: visible;
                                top: 1.8rem;
                                opacity: 1;
                            }
                        }

                        .sub-menu {
                            position: absolute;
                            top: 3rem;
                            left: -5rem;
                            padding: 0.6rem 1rem;
                            border-radius: 0.2rem;
                            background: $white;
                            border: $light-border;
                            width: 16rem;
                            opacity: 0;
                            visibility: hidden;
                            transition: $transition;

                            ol {
                                padding-left: 0;
                                margin-bottom: 0.4rem;
                                padding: 0.4rem 0;
                                border-bottom: 2px solid #e5e5e5;

                                img {
                                    height: 2.5rem;
                                    width: 4rem;
                                    margin-right: 1rem;
                                    border-radius: 0.2rem;
                                }

                                a {
                                    white-space: nowrap;

                                    i {
                                        margin-right: 0.4rem;
                                    }
                                }
                            }
                        }
                    }

                    li {
                        list-style-type: none;
                        margin-left: 1.6rem;

                        a {
                            font-size: 1rem;
                            color: $dark;
                            font-weight: 600;

                            &:hover {
                                color: $secondary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

// mavigation::end

// banner::begin
.banner-section {
    position: relative;

    .social-media-links {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;

        ul {
            a {
                li {
                    height: 2rem;
                    width: 2rem;
                    background: $secondary-color;
                    margin: 0.3rem 0;
                    @include flexPropertyCentered;
                    border-radius: 0.6rem 0 0 0.6rem;

                    &:hover {
                        background: #979797;
                    }

                    i {
                        color: $white;
                        @include globalSmFont;
                    }
                }
            }
        }
    }

    .banner-info {
        background: $gray;
        height: 100%;
        padding: 1rem;
        box-shadow: $box-shadow;
        border-radius: 0 0 1rem 1rem;

        .banner-side-info {
            .banner-side-info-parent {
                display: flex;

                .banner-side-info-childs {
                    background: radial-gradient($primary-color, #004182);
                    border-radius: 0.6rem;
                    flex: 1;
                    margin: 0.4rem 0.4rem;
                    padding: 0.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                    animation: 0.5s linear fadeUpAnimation forwards;

                    .sliding-eff {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 100%;
                        background-color: $secondary-color;
                        z-index: 1;
                        transition: 0.3s;
                    }

                    &:hover .sliding-eff {
                        left: 0;
                    }

                    h3 {
                        font-size: 0.95rem;
                        color: $white;
                        font-weight: 500;
                        z-index: 2;
                    }

                    img {
                        height: 2.7rem;
                        width: 2.7rem;
                        margin-bottom: 0.3rem;
                        z-index: 2;
                    }
                }
            }
        }

        .banner-featured-image {
            @include flexPropertyCentered;
            margin: 0.8rem 0 0 0;

            img {
                width: 90%;
                height: auto;
            }
        }

        .banner-contact {
            margin-top: 1rem;

            h3 {
                color: $dark;
                position: relative;
                text-align: center;
                @include globalMdFont;
                font-weight: 600;

                &::after {
                    @include usePseudoElement;
                    top: 50%;
                    left: 20%;
                    transform: translate(-50%, -50%);
                    background: $secondary-color;
                    height: 0.5rem;
                    width: 2rem;
                    border-radius: 0.6rem;
                }

                &::before {
                    @include usePseudoElement;
                    top: 50%;
                    right: 11%;
                    transform: translate(-50%, -50%);
                    background: $secondary-color;
                    height: 0.5rem;
                    width: 2rem;
                    border-radius: 0.6rem;
                }
            }

            p {
                @include globalSmFont;
                margin-bottom: 1rem;
            }

            .banner-contact-us-btn {
                text-align: center;

                a {
                    background: $primary-color;
                    padding: 0.3rem 1.3rem;
                    color: $white;
                    border-radius: 2rem;
                    @include globalSmFont;
                    transition: $transition;


                    &:hover {
                        background-color: $secondary-color;
                    }

                    i {
                        margin-left: 0.3rem;
                    }
                }
            }
        }
    }
}

// banner::end

// brand message::begin
.brand-message {
    background-image: url("../assets//images/carbon_BG.png");
    background-color: $primary-color;
    padding: 1.2rem 0 2rem 0;
    position: relative;

    &::before {
        @include usePseudoElement;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2rem;
        width: 30%;
        background: $white;
        border-radius: 1rem 1rem 0 0;
    }

    .brand-message-inner {
        text-align: center;
        color: $white;

        h3 {

            font-size: 1.4rem;
        }

        p {
            font-size: 1.2rem;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
}

// brand message::end

// stud-abroad-section::begin
.stud-abroad-section {
    margin-top: 5rem;

    .studymate-header {
        margin-bottom: 2rem;
    }

    .row>* {
        padding-left: 0;
        padding-right: 0;
    }

    .study-abroad-card {
        position: relative;

        .abroad-study-image-wrapper {
            img {
                height: 16rem;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
        }

        .abroad-study-title {
            position: absolute;
            bottom: 1rem;
            left: 1rem;

            h3 {
                color: $white;
                font-size: 1.2rem;
                font-weight: 600;
                border-bottom: 3px solid $secondary-color;
                padding-bottom: 0.2rem;
            }
        }

        &:hover {
            .overlay {
                background: rgba(0, 0, 0, 0);
            }
        }
    }
}

// stud-abroad-section::end

// service-detail-page-section::begin
.service-detail-page-section {
    padding: 0 0 2rem 0;
    background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url("../assets/images/svgs/world_map.svg");
    background-position: center;
    background-size: cover;

    .service-detail-image {
        position: sticky;
        top: 1rem;
        left: 0;
        img {
            height: auto;
            width: 100%;
            border-radius: 0.4rem;
        }
    }
}

// service-detail-page-section::end

.styles_lightroom__1X2qE {
    position: fixed !important;
    z-index: 5000 !important;
}

// gallery-section::begin
.gallery-section {
    padding: 2rem 2rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

// gallery-section::end

// studymate-job-vacancy-section::begin
.studymate-career-detail-wrapper {
    padding: 1rem 0;

    .job-short-detail {
        background: rgb(243, 243, 243);
        width: 100%;
        padding: 1rem;
        border: $light-border;
        border-left: 0.2rem solid rgb(168, 168, 168);
        border-radius: 0.4rem;

        h3 {
            font-size: 1.2rem;
            padding: 0.2rem 0;
        }

        p {
            margin-top: 0.2rem;
            font-size: 0.9rem;
        }

        span {
            color: green;
            font-size: 0.9rem;
        }

    }

    .job-description {
        margin-top: 1rem;
    }

    .apply-btns {
        display: flex;
        margin-top: 1rem;

        a {
            border: 2px solid $primary-color;
            width: 100%;
            margin-top: 0.4rem;
            text-align: center;
            border-radius: 0.4rem;
            padding: 0.4rem;
            color: $primary-color;
            margin-right: 1rem;
            font-size: 0.9rem;

            &:hover {
                background: $secondary-color;
                color: $white;
                border: 2px solid $secondary-color;
            }
        }
    }
}

.career-application {
    padding: 2rem 0;

    .career-application-form {
        border: 2px dashed $dark;
        padding: 1rem;
    }

    .application-short-details {
        background: $primary-color;
        color: $white;
        text-align: center;
        padding: 1rem;
        border-radius: 0.4rem;

        h3 {
            font-size: 1rem;
        }

        p {
            font-size: 0.9rem;
        }
    }

    .application-deco-image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: auto;
            width: 100%;
        }
    }
}

.studymate-job-vacancy-section {
    padding: 1rem 0;

    .job-parent-wrapper {
        display: flex;
        padding: 1rem;
        border: $light-border;
        border-radius: 0.2rem;
        border-left: 0.2rem solid rgb(168, 168, 168);
        background: #f8f8f894;
        margin: 1rem 0;

        .job-childs-wrapper {
            &:first-child {
                width: 24rem;
                margin-right: 1rem;

                .vacancy-image-wrapper {
                    img {
                        height: 12rem;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 0.2rem;
                    }
                }
            }

            &:last-child {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .job-info-wrapper {
                    width: 100%;

                    p {
                        font-size: 0.9rem;
                        color: green;
                    }

                    h3 {
                        margin: 0.4rem 0;
                        font-size: 1.2rem;
                        font-weight: 600;
                    }

                    span {
                        font-size: 0.9rem;
                        color: $dark;
                    }

                    .apply-btns {
                        display: flex;
                        margin-top: 1rem;

                        a {
                            border: 2px solid $primary-color;
                            width: 100%;
                            margin-top: 0.4rem;
                            text-align: center;
                            border-radius: 0.4rem;
                            padding: 0.4rem;
                            color: $primary-color;
                            margin-right: 1rem;
                            font-size: 0.9rem;

                            &:hover {
                                background: $secondary-color;
                                color: $white;
                                border: 2px solid $secondary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

// studymate-job-vacancy-section::end

// preparation-classes-section::begin
.preparation-classes-section {
    padding-top: 2rem;

    .preparation-featured-image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .preparation-class-wrapper {
        h3 {
            font-size: 1rem;
            color: $white;
            font-weight: 600;
            padding: 1rem;
            letter-spacing: 2px;
            border-radius: 0.6rem 0 0.6rem 0.6rem;
            background: $primary-color;
            background-image: url("../../src/assets/images/carbon_BG.png");
        }

        .border-left {
            border-left: 4px solid $secondary-color;
        }

        .border-right {
            border-right: 2px solid $secondary-color;
        }

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: radial-gradient(#0053a8, #004285);
            width: 100%;
            border-radius: 1rem;
            overflow: hidden;
            box-shadow: $box-shadow;
            margin: 1rem 0;

            li {
                list-style-type: none;
                display: grid;
                place-items: center;
                width: 100%;
                padding: 1rem 0;

                a {
                    h4 {
                        text-align: center;
                        font-weight: 500;
                        color: $white;
                        font-size: 1.2rem;
                        padding: 0.5rem 0;
                    }
                }
            }
        }
    }

    .progress-bar-num {
        text-align: center;
        margin-top: 2rem;

        h3 {
            color: $dark;
            font-size: 4rem;
            font-weight: 600;
        }

        p {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    .progressbar-wrapper {
        position: relative;
        border: $light-border;
        height: 1rem;
        width: 100%;
        background: #f8f8f8;
        border-radius: 0.6rem;
        margin-top: 0.5rem;
        overflow: hidden;

        .progress-bar-status {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0%;
            background: $primary-color;
            transition: 2s;
        }

        .progress-bar-status-alt {
            background: $secondary-color;
        }

        .width-100 {
            width: 100%;
        }
    }
}

// preparation-classes-section::end

// message-from-md::begin
.message-from-md-section {
    padding: 2rem 0;
    background: linear-gradient(to right, #ffffffee, #ffffffed), url("../assets/images/svgs/world_map.svg");

    .studymate-md-message-parent {
        display: flex;

        .md-message-childs {
            &:first-child {
                flex: 1;

                img {
                    height: 22rem;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    border: $light-border;
                    transition: $transition;
                    margin-bottom: 0.5rem;
                    border-bottom: 0.2rem solid $secondary-color;

                    &:hover {
                        border: 1rem solid $primary-color;
                    }
                }

                .studymate-md-info {
                    text-align: center;
                    height: 3px;

                    h3 {
                        font-size: 1.1rem;
                        font-weight: 600;
                        text-transform: uppercase;
                    }

                    h4 {
                        font-size: 1.1rem;
                        font-weight: 500;
                        margin: 0.2rem 0;
                        color: $primary-color;
                    }

                    p {
                        font-size: 0.9rem;
                        font-weight: 600;
                    }
                }
            }

            &:last-child {
                flex: 2;
                padding-left: 2rem;

                .md-contact-details {
                    margin-bottom: 2rem;

                    .md-social-media {
                        display: flex;

                        a {
                            .md-social-media-wrapper {
                                height: 2.2rem;
                                width: 2.2rem;
                                background: #828282;
                                border-radius: 0.2rem;
                                @include flexPropertyCentered;
                                margin-right: 0.6rem;

                                &:hover {
                                    background: $primary-color;
                                }

                                i {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// message-from-md::end

// about::begin
.about-section {
    padding: 2rem 0 4rem 0;
    margin-top: 4rem;
    position: relative;

    .about-deco-image {
        position: absolute;
        top: 1rem;
        left: 4rem;
        height: auto;
        width: 20rem;
        z-index: 10;
        opacity: 0.5;
    }

    .about-info-wrapper {
        position: relative;
        z-index: 12;
    }

    .about-bg-image {
        border-radius: 1rem 0 0 1rem;
        overflow: hidden;

        img {
            height: 24rem;
            width: 100%;
            object-position: center;
            object-fit: cover;
        }
        border-left: 0.4rem solid $primary-color;
    }

    .why-choose-studymate-wrapper {
        display: flex;
        margin-top: 4rem;

        .why-choose {
            background: $secondary-color;
            margin-right: 0.5rem;
            flex: 1;
            border-radius: 0.4rem;
            padding: 2rem 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            z-index: 12;

            .why-choose-icon-wrapper {
                background: $white;
                height: 4.6rem;
                width: 4.6rem;
                display: grid;
                place-items: center;
                color: $primary-color;
                border-radius: 100%;
                margin-top: -4.5rem;
                box-shadow: $box-shadow;
                border: $light-border;
            }

            i {
                font-size: 1.6rem;
            }

            h3 {
                font-size: 1.1rem;
                font-weight: 500;
                margin-top: 0.5rem;
                color: $white;
            }
        }
    }
}

// about::end

// about subpage::begin
.about-subpage {

    // padding: 0 0 2rem 0;
    .about-desc {
        margin: 2rem 0;
        border-right: 0.2rem solid $primary-color;
        border-bottom: 0.2rem solid $primary-color;
        ;
        padding: 1rem;
        position: relative;

        .deco_img {
            position: absolute;
            opacity: 0.7;
        }

        .deco_img1 {
            top: 0;
            left: -2rem;
        }

        .deco_img2 {
            bottom: 0;
            right: -2rem;
            transform: rotate(50deg);
        }
    }

    .why-choose-us {
        h3 {
            text-align: center;
            color: $dark;
            font-weight: 600;
            font-size: 1.4rem;
            margin-bottom: 2rem;
        }
    }

    .why-choose-wrapper {
        background: $primary-color;
        padding: 1rem;
        color: $white;
        border-radius: 0.4rem;

        h2 {
            font-size: 1.3rem;
            font-weight: 600;
        }

        .break-line {
            height: 0.2rem;
            width: 6rem;
            background: $white;
            margin: 1rem 0;
        }
    }

    .why-choose-point-parent {
        display: flex;
        justify-content: center;
        align-items: center;

        .why-choose-point-childs {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex: 1;
            border: $light-border;
            height: 10rem;
            margin: 0.5rem;
            background: #f8f8f8;
            box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;

            i {
                margin-bottom: 1rem;
                font-size: 2rem;
                color: $primary-color;
            }

            p {
                font-size: 1.1rem;
                font-weight: 600;
            }
        }
    }

    .message-from-md-sub {
        margin: 2rem 0;
        padding: 3rem 0;
        background: #f8f8f8;

        .message-from-md-sub-parent {
            display: flex;

            .message-from-md-sub-childs {
                flex: 1;

                &:first-child {
                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 0.5rem;
                        border: $light-border;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                &:last-child {
                    background: #2A2F52D4;
                    color: $white;
                    padding: 2rem;
                    border-radius: 0.5rem;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    margin-left: -5rem;
                    height: 100%;

                    h3 {
                        margin-bottom: 2rem;
                        font-size: 1.1rem;
                        font-weight: 600;
                    }

                    p {
                        text-align: justify;
                        font-size: 1rem;
                    }

                    h2 {
                        font-size: 1rem;
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                    }

                    h4 {
                        font-size: 1rem;
                    }

                    .line-break {
                        margin-top: 2rem;
                        height: 0.2rem;
                        width: 30%;
                        background: $white;
                    }
                }
            }
        }
    }
}

// about subpage::end

// application::begin
.application-section {
    background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("../assets/images/bg1.jpg");
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    background-repeat: no-repeat;
    margin: 0rem 0 0rem 0;
    padding: 4rem 0;

    .application-form-container {
        background: $white;
        box-shadow: $box-shadow;
        padding: 1rem;
        border-radius: 0.5rem;

        .apply-img {
            @include flexPropertyCentered;

            img {
                height: auto;
                width: 14rem;
                margin-bottom: 1rem;
            }
        }

        .application-inner {
            border: 2px dashed $primary-color;
            padding: 1rem;

            h3 {
                color: $dark;
                @include globalMdFont;
                text-align: start;
                margin-bottom: 1rem;
            }

        }
    }

    .apply-features {
        background: $white;
        box-shadow: $box-shadow;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        border-top: 0.3rem solid $primary-color;

        .apply-feature-inner {
            @include flexPropertyCentered;
            margin: 1rem 0;

            img {
                width: 80%;
                height: auto;
            }
        }

        .join-us-arrow {
            @include flexPropertyCentered;
            margin: 4rem 0 1rem 0;

            img {
                width: 70%;
            }
        }
    }

    .apply-featured {
        h3 {
            font-size: 1.4rem;
            color: $dark;
        }

        h4 {
            font-size: 1.2rem;
            color: $secondary-color
        }

        ul {
            li {
                @include noListStyle;
                @include globalSmFont;
                color: $dark;
                font-weight: 700;
                margin: 0.5rem 0;

                i {
                    color: $primary-color;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

// application::end

// contact-section::begin
.contact-section {
    padding: 2rem 0 3rem 0;
    background-color: #f8f8f8;
    position: relative;

    .contact-deco-image {
        position: absolute;
        top: 1rem;
        left: 1rem;
        height: auto;
        width: 16rem;
        z-index: 10;
    }

    .contact-deco-image-alt {
        position: absolute;
        top: 1rem;
        right: 1rem;
        height: auto;
        width: 12rem;
        z-index: 10;
        transform: rotate(45deg);
    }

    .contact-info-wrapper {
        background: $white;
        border-radius: 0.6rem;
        padding: 1rem;
        text-align: center;
        border: $light-border;
        margin-top: 1rem;
        transition: $transition;
        position: relative;
        z-index: 12;

        &:hover {
            background: $white;
            box-shadow: $box-shadow;
            border: 1px solid $primary-color;
            transform: scale(1.1);
        }

        i {
            font-size: 1.6rem;
            margin-bottom: 1rem;
            color: $primary-color;
        }

        h3 {
            font-size: 1.1rem;
        }
    }

    .callback-form {
        background: $white;
        border: $light-border;
        padding: 1rem;
        margin-top: 2rem;
        border-radius: 0.4rem;

        .drop-a-message {
            h3 {
                font-size: 1rem;
                font-weight: 600;
            }

            p {
                font-size: 0.9rem;
                color: red;
            }
        }
    }

    .studymate-location-map {
        margin-top: 2rem;

        iframe {
            height: 23.5rem;
            width: 100%;
            border-radius: 0.4rem;
            box-shadow: 0 0 10px 0 #00000051;
        }
    }

}

// contact-section::end

// team-section::begin
.studymate-team-members-section {
    padding: 2rem 0;

    .team-member-card {
        border: $light-border;
        transition: $transition;
        border-bottom: 3px solid $primary-color;

        &:hover {
            transform: scale(1.1);
        }

        .team-member-image {
            img {
                height: 18rem;
                width: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        .member-details {
            text-align: center;
            padding: 1rem 0;

            h3 {
                font-size: 1.2rem;
                color: $dark;
                font-weight: 500;
            }

            p {
                margin-top: 0.1rem;
                color: $primary-color;
                font-size: 1rem;
                font-weight: 500;

            }
        }
    }
}

// team-section::end

// footer:section::begin
.brand-stripe {
    height: auto;
    width: 100%;
}

.footer-section {
    background-color: $primary-color;
    padding: 2rem 0;

    .connect-with-us-facebook {
        iframe {
            height: 24rem;
        }
    }

    .footer-logo {
        .footer-logo-img {
            img {
                height: auto;
                width: 80%;
            }
        }
    }

    .footer-contact-info {
        margin-top: 1.2rem;

        ul {
            li {
                font-weight: 500;
                color: $white;
                @include noListStyle;
                margin: 0.8rem 0;

                a {
                    color: $white;
                }

                i {
                    margin-right: 0.6rem;
                    width: 1rem;
                }
            }
        }
    }

    .footer-social-icons {
        margin-top: 1.6rem;

        ul {
            display: flex;
            justify-content: flex-start;

            a {
                li {
                    height: 2.5rem;
                    width: 2.5rem;
                    border-radius: 0.2rem;
                    background: $white;
                    @include flexPropertyCentered;
                    margin-right: 1rem;

                    &:hover {
                        background-color: $secondary-color;

                        i {
                            color: $white;
                        }
                    }

                    i {
                        font-size: 1rem;
                        color: $primary-color;
                    }
                }
            }
        }
    }

    .footer-links {
        margin-top: 2rem;

        h3 {
            color: $white;
            font-size: 1.2rem;
        }

        ul {
            li {
                @include noListStyle;
                margin: 0.8rem 0;

                a {
                    color: $white;

                    &:hover {
                        color: $secondary-color;
                    }

                    i {
                        margin-right: 0.6rem;
                    }
                }
            }
        }
    }
}

.copy-right {
    background: #003161;
    padding: 0.4rem 0;

    .copy-right-parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .copy-right-childs {
            p {
                color: $white;
                font-weight: 400;
                font-size: 0.9rem;
                margin-bottom: 0;

                a {
                    color: $white;
                }
            }
        }
    }
}

// footer:section::end

// success page::begin
.success-message-wrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.951), white);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
        height: auto;
        width: 50%;
    }

    h6 {
        i {
            font-size: 2.6rem;
            color: green;
            margin: 1rem 0;
        }
    }

    p {
        margin-bottom: 0;
        color: green;
    }

    a {
        background: $primary-color;
        color: $white;
        padding: 0.4rem 1.2rem;

        &:hover {
            background-color: $secondary-color;
            color: $white;
        }
    }
}

// success page::end

@keyframes fadeUpAnimation {
    from {
        opacity: 0;
        transform: translateY(-2rem);
    }

    to {
        opacity: 1;
        transform: translateY(0rem);
    }
}

@media only screen and (max-width: 3000px) {

    .container {
        max-width: 1260px !important;
    }

    .navigation-section .menu .menu-childs:first-child {
        width: auto;
    }

}

@media only screen and (max-width: 1092px) {

    html {
        font-size: 85%;
    }

}

@media only screen and (max-width: 960px) {

    html {
        font-size: 100%;
    }

    .navigation-section .menu .menu-childs:last-child {
        background: radial-gradient(#0053a8, #004890);
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 5500;

        ul {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 4rem;

            li {
                margin: 1rem 0;

                a {
                    color: $white;
                    font-weight: 500;
                }
            }
        }
    }

    .navigation-section .menu .menu-childs:last-child ul .sub-menu-wrapper {
        .sub-menu {
            ol {
                a {
                    color: $primary-color;
                }
            }
        }
    }

    .navigation-section .menu .menu-childs:first-child {
        width: 100%
    }

    .navigation-section .menu .menu-childs:first-child .toggle-open-btn {
        display: block;
    }

    .navigation-section .toggle-close-btn {
        display: block;
    }

}

@media only screen and (max-width: 768px) {
    .studymate-top-navigation .top-navigation-info .top-navigation-parent .top-navigation-childs:first-child {
        ul {
            display: none;
        }
    }

    .message-from-md-section .studymate-md-message-parent {
        flex-direction: column;
    }

    .message-from-md-section .studymate-md-message-parent .md-message-childs:last-child .md-contact-details .md-social-media {
        margin-top: 5rem;
        justify-content: center;
    }

    .message-from-md-section .studymate-md-message-parent .md-message-childs:last-child {
        padding-left: 0;
    }

    .preparation-classes-section .preparation-featured-image img {
        margin: 2rem 0;
    }

    .about-section .why-choose-studymate-wrapper {
        flex-direction: column;
    }

    .about-section .why-choose-studymate-wrapper .why-choose {
        margin-bottom: 0.5rem;
    }

    .application-section .apply-features {
        margin-top: 2rem;
    }

    .studymate-team-members-section .team-member-card {
        margin-bottom: 2rem;
    }

    .studymate-job-vacancy-section .job-parent-wrapper {
        flex-direction: column;
    }

    .studymate-job-vacancy-section .job-parent-wrapper .job-childs-wrapper:last-child .job-info-wrapper p {
        margin-top: 1rem;
    }

    .career-application .application-short-details {
        margin-top: 1rem;
    }
}

@media only screen and (max-width: 492px) {
    .banner-section .social-media-links ul a li {
        height: 1.4rem;
        width: 1.4rem;
        border-radius: 0.2rem 0 0 0.2rem;

        i {
            font-size: 0.7rem;
        }
    }
}

@media only screen and (max-width: 392px) {
    html {
        font-size: 80%;
    }
}