
.operation-message-wrapper {
    position: sticky;
    top: 0;
    z-index: 5;
    padding: 0.5rem;
    color: #fff;
    font-size: 0.9rem;
    text-align: center;
    border-radius: 0.2rem;
    margin: 0.5rem 0.5rem 0.2rem 0.5rem;
    animation: animate-success-box 1s linear forwards;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.operation-message-wrapper button {
    background: none;
    border: 2px solid #fff;
    outline: none;
    cursor: pointer;
    color: #fff;
    height: 1.4rem;
    width: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-type-only {
    background: rgb(0, 163, 0);
}

.danger-type-only {
    background: red;
}


@keyframes animate-success-box {
    from {
        opacity: 0;
        transform: translateY(1rem);
    }
    to {
        opacity: 1;
        transform: translateY(0rem);
    }
}