$primary-color: #0053a8;
$secondary-color: #f67e1d;
$white: #fff;
$dark: #292929;
$gray: #f8f8f8;

$box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;;
$text-shadow: 2px 2px #0000008a;
$light-border: 1px solid #e2e2e2;
$transition: 0.4s;
$transition_half: 0.5s;