.slider-wrapper {
    height: 100%;
    width: 100%;
}

.sliders-wrapper {
    background: #fff !important;
    /* overflow: hidden; */
    height: 100%;
}

.sliders {
    height: 100%;
    width: 100%;
    position: relative;
}

.sliders .slider-btn {
    position: absolute;
    top: 50%;
    z-index: 10;
    background: none;
    outline: none;
    border: none;
    font-size: 1.6rem;
    cursor: pointer;
    color: rgb(223, 223, 223);
}

.sliders .slider-btn:hover {
    color: white;
}

.sliders .slider-btn-left {
    left: 1rem;
}

.sliders .slider-btn-right {
    right: 1rem;
}

.each-slider-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.each-slider-img img {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
}

.slider-img-active {
    display: block;
    animation: animateSliderImages 0.4s linear forwards;
}

.slider-img-deactive {
    display: none;
}

@keyframes animateSliderImages {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 768px) {
    .slider-wrapper {
        height: 20rem;
    }
}