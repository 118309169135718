.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    display: grid;
    place-items: center;
    max-width: 100%;
    z-index: 112000;
}

.loading>div {
    width: 10vmax;
    height: 10vmax;
    border-bottom: 5px solid rgba(0, 0, 0, 0.719);

    border-radius: 50%;

    animation: loadingRotate 800ms linear infinite;
}

@keyframes loadingRotate {
    to {
        transform: rotateZ(-360deg);
    }
}