// ----------colors::end----------
$global-primary-color: #0053a8;
$global-secondary-color: #f67e1d;
$global-white: #fff;
$global-color-dark: #292929;
$global-color-gray: #f8f8f8;
$dashboard-primary-color: #6c50f6;
// ----------colors::end----------
// ************************************
// ----------fonts::begin----------
$global-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
;
$global-text-shadow: 2px 2px #0000008a;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;
$light-border: 1px solid #e2e2e2;
// ----------fonts::end----------
// ************************************
// ----------transitions::begin----------
$global-transition: 0.2s;
$global-transition-half: 0.5s;
$global-transition-one: 1s;
$global-transition-two: 2s;

// // ----------transitions::end----------
.jodit-react-container {
    background: $global-white !important;
}

.jodit-status-bar a.jodit-status-bar-link {
    display: none !important;
}

.jodit-container:not(.jodit_inline) .jodit-wysiwyg {
    background: #fff;
    min-height: 200px !important;
}

.jodit .jodit-workplace .jodit-wysiwyg,
.jodit .jodit-workplace .jodit-wysiwyg_iframe,
.jodit-container .jodit-workplace .jodit-wysiwyg,
.jodit-container .jodit-workplace .jodit-wysiwyg_iframe {
    padding: 1rem 2rem !important;
}

.download-cv-btn {
    a {
        background: $dashboard-primary-color;
        padding: 0.4rem 0;
        text-align: center;
        color: $global-white;
        font-size: 0.9rem;
        display: block;
        border-radius: 0.2rem;
    }
}

.admin-profile-info {
    padding: 1rem;
    height: 100%;
    border-radius: 0.2rem;
    border: $light-border;
    box-shadow: $global-box-shadow;

    .admin-profile-image {
        display: flex;
        justify-content: center;

        img {
            height: 6rem;
            width: 6rem;
            border-radius: 100%;
            object-fit: cover;
            object-position: center;
            border: 0.15rem solid #e1e1e1;
        }
    }

    .admin-info {
        h3 {
            text-align: center;
            font-size: 1rem;
            font-weight: $fw-600;
            margin-top: 1rem;
            margin-bottom: 0;
        }

        p {
            text-align: center;
            font-size: 0.9rem;
            font-weight: $fw-600;
            color: green;
        }

        .admin-info-details {
            display: flex;
            justify-content: flex-start;

            .admin-info-detail {
                &:first-child {
                    flex: 1;
                }

                &:last-child {
                    flex: 2;
                }

                margin: 0.5rem 0;

                h2,
                h4 {
                    font-size: 0.9rem;
                    margin-bottom: 0;
                    font-weight: $fw-600;
                    white-space: nowrap;
                }

                h4 {
                    color: $dashboard-primary-color;
                }
            }
        }

        a {
            margin-top: 1rem;
            font-size: 0.9rem;
            background: $dashboard-primary-color;
            color: $global-white;
            padding: 0.4rem 0;
            display: block;
            text-align: center;
            border-radius: 0.4rem;
        }
    }
}

.detail-page-sub-heading {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.table-image-only {
    img {
        height: 4rem;
        width: 4rem;
        object-fit: cover;
        object-position: center;
        border: $light-border;
    }
}

.dashboard-detail-page {
    .dashboard-detail-page-wrapper {
        border: $light-border;
        padding: 1rem;

        .dashboard-detail-page-parent {
            display: flex;
            margin-bottom: 0.8rem;

            .dashboard-detail-page-childs {
                &:first-child {
                    width: 12rem;

                    p {
                        color: $dashboard-primary-color;
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }

                &:last-child {
                    width: 100%;

                    p {
                        font-size: 1rem;
                    }

                    ul {
                        display: flex;

                        li {
                            list-style-type: none;
                            margin-right: 0.6rem;

                            img {
                                height: 5rem;
                                width: 5rem;
                                border: $light-border;
                                object-fit: contain;
                                object-position: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dashboard-forms {
    background: #f8f8f8;
    border: $light-border;

    h4 {
        font-size: 0.9rem;
        font-weight: 600;
        color: $global-color-dark;
        background: #e1e1e1;
        padding: 0.6rem;
    }

    form {
        padding: 1rem;

        .input-group {
            margin-bottom: 1rem !important;
            flex-direction: column;

            &:first-child {
                margin: 0;
            }
        }

        .dashboard-label {
            font-size: 0.9rem;
            font-weight: 600;
            color: $global-color-dark;
            margin-bottom: 0.3rem;

            span {
                color: red;
                font-size: 0.8rem;
            }
        }

        .dashboard-input {
            width: 100%;
            outline: none;
            border: 1px solid #c8c8c8;
            border-radius: 0.2rem;
            padding: 0.4rem 0.6rem;
            border-bottom: 2px solid $dashboard-primary-color;
            font-size: 0.9rem;

            &::placeholder {
                font-size: 0.9rem;
            }
        }

        .input-group-type-file {
            flex-direction: column;
        }

        .dashboard-input-type-file {
            border: none;
            width: 100%;
        }

        .dashboard-input-type-file::file-selector-button {
            cursor: pointer;
            width: 100%;
            z-index: 2;
            color: #fff;
            background-color: rgb(150, 149, 149);
            border: none;
            border-radius: 0.2rem;
            font-size: 0.9rem;
            padding: 0.3rem 0.6rem;
        }

        .form-danger-info {
            font-size: 0.9rem;
            color: red;
        }

        .dashboard-submit-btn100 {
            margin-top: 1rem;

            button {
                font-size: 0.9rem;
                width: 100%;
                background: $dashboard-primary-color;
                padding: 0.3rem;
                color: $global-white;
                outline: none;
                border: none;
                border-radius: 0.2rem;

                i {
                    transform: translateX(2rem);
                    transition: 0.2s;
                    opacity: 0;
                    margin-left: 0.4rem;
                }

                &:hover {
                    background: #555555;

                    i {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
            }
        }

        .form-img-preview {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0.6rem 0;

            img {
                height: 6rem;
                width: 10rem;
                object-fit: cover;
                object-position: center;
                border: $light-border;
            }

            .file-remove-btn {
                margin-top: 1rem;

                button {
                    background: rgb(233, 0, 0);
                    border: none;
                    outline: none;
                    color: #fff;
                    font-size: 0.7rem;
                    padding: 0.2rem 0.4rem;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }
}

.dashboard-breadcrumb {
    font-size: 0.9rem;
    color: $global-color-dark;
    font-weight: 600;
    margin: 0.6rem 0;

    a {
        color: $global-color-dark;
    }
}

.whiteSpaceTrue {
    white-space: nowrap;
}

.dashboard-data-table {
    font-size: 0.9rem;

    thead {
        background: #f8f8f8;
    }

    td {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    tbody {
        vertical-align: middle;
    }

    .table-image {
        img {
            height: 3rem;
            width: 3rem;
            object-fit: cover;
            object-position: center;
            border: $light-border;
        }
    }

    .table-action {
        button {
            font-size: 0.7rem;
            font-weight: 500;
            padding: 0.2rem 0.4rem;
            border: none;
            outline: none;
            margin-right: 0.4rem;
            color: $global-white;
            opacity: 0.8;

            &:hover {
                background: #3a3a3a;
            }

        }

        .view {
            background: $dashboard-primary-color;
        }

        .update {
            background: rgb(0, 97, 0);
        }

        .delete {
            background: rgb(164, 0, 0);
        }
    }
}


.dashboard-section {
    .dashboard-parent-wrapper {
        display: flex;

        .dashboard-childs-wrapper {

            &:first-child {
                background-color: #f8f8f8;
                border: 1px solid #d4d4d4;
                box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
                position: fixed;
                z-index: 1000;

                .side-navigation {

                    height: 40rem;
                    overflow-y: scroll;
                    width: 14rem;

                    &::-webkit-scrollbar {
                        width: 2px;
                        background: rgb(240, 240, 240);
                    }

                    &::-webkit-scrollbar-track {
                        border-radius: 0.2rem;
                        background: transparent;
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0;
                        background: rgba(0, 0, 0, 0.1);
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
                    }

                    ul {
                        padding: 0 1rem !important;
                        margin: 1.4rem 0 !important;

                        h3 {
                            font-weight: 500;
                            color: #474747;
                            font-size: 1rem;
                        }

                        li {
                            padding-left: 1rem;
                            list-style-type: none;
                            margin: 1rem 0;

                            button {
                                background: none;
                                border: none;
                            }

                            a {
                                color: #313131;
                                font-weight: 500;
                                font-size: 1rem;

                                &:hover {
                                    text-decoration: none;
                                    color: $dashboard-primary-color;
                                }

                                i {
                                    color: $dashboard-primary-color;
                                    margin-right: 1rem;
                                    width: 1rem;
                                }
                            }
                        }
                    }
                }
            }

            &:last-child {
                padding-left: 14rem;
                width: 100%;

                .dashboard-header-wrapper {
                    background-color: #f8f8f8;
                    border: 1px solid #d4d4d4;
                    display: flex;
                    justify-content: space-between;
                    height: 3rem;

                    .dashboard-header-childs {
                        &:first-child {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            h3 {
                                margin-bottom: 0;
                                font-weight: $fw-600;
                                margin-left: 1rem;
                                font-size: 1rem;
                                color: $dashboard-primary-color;

                                span {
                                    color: #5a5a5a;
                                    font-size: 0.8rem;
                                }
                            }
                        }

                        &:last-child {
                            display: flex;
                            align-items: center;
                            padding: 0rem 1rem;
                            max-width: 100%;

                            ul {
                                display: flex;

                                .language-taggle-option {
                                    select {
                                        font-size: 0.8rem;
                                        border: $light-border;
                                        outline: none;
                                    }

                                    i {
                                        margin-right: 0.5rem;
                                    }
                                }

                                li {
                                    list-style-type: none;
                                    margin: 0rem 1.4rem;

                                    a {
                                        color: $global-color-dark;
                                        font-weight: 600;

                                        &:hover {
                                            color: $dashboard-primary-color;
                                            text-decoration: none;
                                        }

                                        i {
                                            width: 1rem;
                                            margin-right: 0.6rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .dashboard-content-wrapper {
        .report-card-parent {
            border: 1px solid #dadada;
            display: flex;
            margin: 1rem 0;

            &:hover {
                background-color: #f8f8f8;
            }

            .report-card-childs {
                padding: 1rem;

                &:first-child {
                    flex: 1;

                    p {
                        color: $global-color-dark;
                        font-weight: 500;
                        white-space: nowrap;
                        font-size: 1rem;
                    }

                    h3 {
                        color: $global-color-dark;
                        font-weight: 600;
                        font-size: 1.4rem;
                        margin: 0.4rem 0;
                    }

                    h4 {
                        color: $global-color-dark;
                        font-weight: 400;
                        font-size: 0.8rem;
                        margin: 0.16rem 0;
                    }

                    a {
                        color: $dashboard-primary-color;
                        font-weight: 500;
                        text-decoration: none;
                        white-space: nowrap;
                        font-size: 0.9rem;

                        &:hover {
                            color: $global-color-dark;
                        }
                    }
                }

                &:last-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    i {
                        font-size: 3rem;
                        color: $global-color-dark;
                        opacity: 0.4;
                    }
                }
            }
        }
    }
}

.dashboard-content-wrapper {
    padding: 0.3rem 0.4rem;

    h1 {
        font-size: 1rem;
        color: $global-color-dark;
        margin-bottom: 0;
        padding: 0rem 1rem;

        a {
            color: $dashboard-primary-color;
            font-size: 0.9rem;
        }
    }
}

.flashDangerAnimation {
    animation: animateFlash 2s ease-in-out infinite;
}

@keyframes animateFlash {
    from {}

    to {
        border-bottom: 1px solid red;
        background: rgba(255, 0, 0, 0.193);
    }
}

.form-img-preview-array {
    .form-img-preview-array-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    img {
        height: 5rem;
        width: 5rem;
        margin: 0rem 0.3rem;
        border: $light-border;
        object-fit: cover;
        object-position: top;
    }

    .removeImgBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0rem 0.4rem;
        color: $global-white;
        background: rgb(233, 0, 0);
        font-size: 0.9rem;
        padding: 0.2rem 0;
        cursor: pointer;

        &:hover {
            background: $dashboard-primary-color;
        }
    }

    .file-remove-btn {
        margin-top: 1rem;
        display: flex;
        justify-content: center;

        button {
            background: rgb(233, 0, 0);
            border: none;
            outline: none;
            color: #fff;
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

.media-card-wrapper {
    background: #f8f8f8;
    border: $light-border;
    padding: 0.4rem;
    margin-bottom: 1rem;

    .media-card-image {
        img {
            height: 10rem;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .orientation-sty {
        font-size: 0.9rem;
        font-weight: 500;    
        margin-top: 0.2rem;    
    }

    .create_date {
        color: $dashboard-primary-color;
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }

    .media-action-btns {
        display: flex;
        justify-content: space-between;

        button {
            width: 100%;
            color: $global-white;
            border: none;
            outline: none;
            font-size: 0.8rem;
            padding: 0.2rem 0;
            border-radius: 0.2rem;

            &:hover {
                background: #414141;
            }
        }

        .delete-btn {
            background: rgb(219, 0, 0);
            margin-right: 0.4rem;
        }

        .update-btn {
            background: green;
        }
    }
}

.update-order-wrapper {
    border: 1px dashed $dashboard-primary-color;
    padding: 1rem;
    box-shadow: $global-box-shadow;
}