.confirmation-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5001;
}

.confirmation-modal-inner-wrapper {
    background: #fff;
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.3rem;
    border: 1px solid #e6e6e6;
    animation: scalUpAnimation 0.3s ease-in-out forwards;
}

.confirmation-modal-inner-wrapper h3 {
    font-size: 0.9rem;
    color: #222;
}

.confirmation-modal-inner-wrapper p {
    font-size: 1.1rem;
    color: #222;
    margin: 0.4rem 0;
    font-weight: 400;
}

.modals-action-btns button:hover {
    background: #646464;
}

.modals-action-btns button {
    color: #fff;
    padding: 0.3rem 0.6rem;
    font-size: 0.9rem;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0.2rem;
}

.modal-proceed-btn {
    background: green;
    margin-right: 1rem;
}

.modal-cancel-btn {
    background: red;
}

@keyframes scalUpAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}