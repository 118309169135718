@mixin noMarginPadding {
    margin: 0;
    padding: 0;
}

@mixin noListStyle {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin noWhiteSpace {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin flexPropertyCentered {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin absoluteCentered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin usePseudoElement {
    content: "";
    position: absolute;
}

@mixin countColumn($count) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
}

@mixin globalESmFont {
    font-size: 0.9rem;
}

@mixin globalSmFont {
    font-size: 1rem;
}

@mixin globalMdFont {
    font-size: 1.2rem;
}

@mixin globalLgFont {
    font-size: 1.4rem;
}

