.studymate-notification-wrapper {
    padding: 0.4rem 0;
    border-radius: 0.4rem;
    animation: animateNotification 0.3s ease-in-out forwards;
}

.studymate-notification-wrapper h4 {
    font-size: 0.9rem;
    color: #fff;
    text-align: center;
    font-weight: 400;
}

.close-btn {
    position: absolute;
    top: 0.2rem;
    right: 1rem;
    cursor: pointer;
}

.close-btn i {
    color: #fff;
    font-size: 1rem;
}

.studymate-notification-wrapper.danger {
    background: rgb(211, 0, 0);
}

.studymate-notification-wrapper.success {
    background: green;
}

.studymate-notification-wrapper.warning {
    background: orange;
}

@keyframes animateNotification {
    0% {
        transform: translateX(-0.5rem);
    }
    50% {
        transform: translateX(1rem);
    }
    100% {
        transform: translateX(0rem);
    }
}